import React from 'react'

export const Loader = () => {
    return (
        <>
            <div className='loader'>
                <h1>Loading...</h1>
            </div>
        </>
    )
}
